import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer>
      <div className="has-text-centered">
        <p>Copyright Kevin’s Shop</p>
      </div>
    </footer>
  );
}

export default Footer;
