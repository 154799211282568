import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// components
import Layout from "../../components/Layout/Layout";
import Modal from "../../components/Modal/Modal";
import Loader from "../../components/Loader/Loader";

// actions
import { getAllProducts } from "../../store/actions/products";

// helpers
import axios from "axios";
import { getDate, getDefaultImageUrl } from "../../utils/helpers";

const ViewProduct = () => {
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [success, setSuccess] = useState("");

  const { productId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchProduct();
  }, [productId]);

  const fetchProduct = () => {
    const authToken = localStorage.getItem("Authorization");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .get("/admin/product/", { params: { productId } })
      .then((response) => {
        setProduct(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setModalError(error.response?.data?.error || "An error occurred");
      });
  };

  const handleDelete = (id) => {
    const authToken = localStorage.getItem("Authorization");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .delete(`/admin/product/${id}`)
      .then((response) => {
        setDisplayModal(true);
        setSuccess(response.data.message);
        setTimeout(() => {
          setDisplayModal(false);
          dispatch(getAllProducts());
          navigate("/admin/products");
        }, 1500);
      })
      .catch((error) => {
        setDisplayModal(true);
        setModalError(error.response?.data?.error || "An error occurred");
        setTimeout(() => {
          setDisplayModal(false);
        }, 1500);
      });
  };

  return (
    <Layout>
      <Modal error={modalError} message={success} active={displayModal} />
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds">
            <div className="card">
              {loading && <Loader />}
              <header className="card-header">
                <p className="card-header-title is-uppercase is-size-4">
                  {product.title}
                </p>
              </header>
              <div className="card-content">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <span className="has-text-weight-bold">Title:</span>
                      </td>
                      <td>{product.title}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="has-text-weight-bold">
                          Item Number:
                        </span>
                      </td>
                      <td>{product.itemNumber}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="has-text-weight-bold">Price:</span>
                      </td>
                      <td>{product.price}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="has-text-weight-bold">Currency:</span>
                      </td>
                      <td>{product.currency}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="has-text-weight-bold">Brand:</span>
                      </td>
                      <td>{product.brand}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="has-text-weight-bold">Material:</span>
                      </td>
                      <td>{product.material}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="has-text-weight-bold">Weight:</span>
                      </td>
                      <td>{product.weight}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="has-text-weight-bold">
                          Description:
                        </span>
                      </td>
                      <td>{product.description}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="has-text-weight-bold">Category:</span>
                      </td>
                      <td>{product.category}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="has-text-weight-bold">Status:</span>
                      </td>
                      <td>{product.isActive ? "Public" : "Draft"}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="has-text-weight-bold">Colors:</span>
                      </td>
                      <td>{product?.colors?.join(", ")}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="has-text-weight-bold">Sizes:</span>
                      </td>
                      <td>{product?.sizes?.join(", ")}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="has-text-weight-bold">Types:</span>
                      </td>
                      <td>{product?.types?.join(", ")}</td>
                    </tr>
                    <tr>
                      <td>
                        <span className="has-text-weight-bold">
                          Created at:
                        </span>
                      </td>
                      <td>{getDate(product.createdAt)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <footer className="card-footer">
                <Link to={`/admin/products/edit/${productId}`}>
                  <button className="button is-link">Edit</button>
                </Link>
                <button
                  className="button is-danger"
                  onClick={() => handleDelete(productId)}
                >
                  Delete
                </button>
              </footer>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="card">
              <div className="card-image">
                {getDefaultImageUrl(product?.images)?.url && (
                  <figure className="image is4by3">
                    <img
                      src={getDefaultImageUrl(product?.images)?.url || ""}
                      alt="product"
                    />
                  </figure>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ViewProduct;
