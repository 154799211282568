import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// actions
import { getAllProducts } from "../../store/actions/products";

// components
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modal/Modal";
import Pagination from "../../components/Pagination/Pagination";

// helpers
import { chunkArray } from "../../utils/helpers";
import {
  NUMBER_OF_PRODUCTS_ON_PAGE,
  PAGINATION_STYLE,
  PAGINATION_SIZE,
  NUMBER_OF_PAGINATION_ELEMENTS,
  PAGINATION_ALIGNMENT,
} from "../../constants/pagination";
import axios from "axios";

const Products = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [success, setSuccess] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const user = useSelector((state) => state.user.data);

  useEffect(() => {
    const page = searchParams.get("page") || 1;
    setPageNumber(Number(page));
    if (!products.data) {
      dispatch(getAllProducts());
    }
  }, [searchParams, products.data, dispatch]);

  const handleDelete = (id) => {
    const authToken = localStorage.getItem("Authorization");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .delete(`/admin/product/${id}`)
      .then((response) => {
        setDisplayModal(true);
        setSuccess(response.data.message);
        setTimeout(() => {
          setDisplayModal(false);
          dispatch(getAllProducts());
        }, 1500);
      })
      .catch((error) => {
        setDisplayModal(true);
        setModalError(error.response?.data?.error || "An error occurred");
        setTimeout(() => {
          setDisplayModal(false);
        }, 1500);
      });
  };

  const pageLink = (no) => {
    return no === 1 ? "/admin/products/" : `/admin/products/?page=${no}`;
  };

  const displayedProducts = chunkArray(
    products.data,
    NUMBER_OF_PRODUCTS_ON_PAGE
  );
  const numberOfPages = Math.ceil(
    products.data.length / NUMBER_OF_PRODUCTS_ON_PAGE
  );

  if (user.loading) {
    return <Loader />;
  }

  return (
    <Layout>
      <Modal error={modalError} message={success} active={displayModal} />
      <div className="control">
        <h1 className="subtitle">Products</h1>
        <Link to="/admin/products/new" className="button is-primary">
          New Product
        </Link>
        {products.loading && <Loader />}
      </div>
      {products.data && (
        <table className="table" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Title</th>
              <th className="has-text-right">Price</th>
              <th>Category</th>
              <th>Status</th>
              <th>View</th>
              <th>Stock</th>
              <th>Edit Product</th>
              <th>Delete Product</th>
            </tr>
          </thead>
          <tbody>
            {displayedProducts[pageNumber - 1]?.map((product) => (
              <tr key={product.id}>
                <td>{product.title}</td>
                <td className="has-text-right">
                  {product.price} {product?.currency}
                </td>
                <td>
                  <Link to={`/admin/categories/view/${product.category.id}`}>
                    {product.category.name}
                  </Link>
                </td>
                <td>{product?.isActive ? "Public" : "Draft"}</td>
                <td>
                  <Link to={`/admin/products/view/${product.id}`}>
                    <button className="button is-link">View</button>
                  </Link>
                </td>
                <td>
                  <Link to={`/admin/products/stock/${product.id}`}>
                    <button className="button is-link">Manage</button>
                  </Link>
                </td>
                <td>
                  <Link to={`/admin/products/edit/${product.id}`}>
                    <button className="button is-link">Edit</button>
                  </Link>
                </td>
                <td>
                  <button
                    className="button is-danger"
                    onClick={() => handleDelete(product.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Pagination
        total={numberOfPages}
        active={pageNumber}
        size={PAGINATION_SIZE}
        style={PAGINATION_STYLE}
        alignment={PAGINATION_ALIGNMENT}
        show={NUMBER_OF_PAGINATION_ELEMENTS}
        pageLink={pageLink}
      />
    </Layout>
  );
};

export default Products;
