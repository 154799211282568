import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

// actions
import { getAllProducts } from "../../store/actions/products";

// components
import Layout from "../../components/Layout/Layout";
import Error from "../../components/Error/Error";
import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modal/Modal";
// helpers
import { validateNewProductData } from "../../utils/validators";
import MyDropdown from "../../components/Dropdown/multiple-dropdown";
// import { CURRENCY_LIST } from "../../utils/constants";
const typesList = [
  { name: "Male", id: "Male" },
  { name: "Female", id: "Female" },
];

const NewProduct = () => {
  const [formState, setFormState] = useState({
    title: "",
    itemNumber: "",
    brand: "",
    price: "",
    currency: "USD",
    material: "",
    weight: "",
    description: "",
    category: "",
    fetchedCategories: [],
    loading: true,
    displayModal: false,
    modalError: "",
    errors: "",
    success: "",
    colors: [],
    sizes: [],
    types: [],
  });

  const [title, setTitle] = useState("");
  const [itemNumber, setItemNumber] = useState("");
  const [brand, setBrand] = useState("");
  const [price, setPrice] = useState("");
  const [material, setMaterial] = useState("");
  const [weight, setWeight] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colorsList, setColorsList] = useState([]);
  const [sizesList, setSizesList] = useState([]);
  const [types, setTypes] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getMasterList();
    const fetchCategories = async () => {
      const authToken = localStorage.getItem("Authorization");
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      try {
        const response = await axios.get("/admin/categories");
        setFormState((prevState) => ({
          ...prevState,
          fetchedCategories: response.data,
          loading: false,
        }));
      } catch (error) {
        setFormState((prevState) => ({
          ...prevState,
          displayModal: true,
          modalError: error.response?.data?.error || "An error occurred",
        }));
        setTimeout(() => {
          setFormState((prevState) => ({
            ...prevState,
            displayModal: false,
            modalError: "",
          }));
        }, 1500);
      }
    };
    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") setTitle(value);
    else if (name === "itemNumber") setItemNumber(value);
    else if (name === "brand") setBrand(value);
    else if (name === "price") setPrice(value);
    else if (name === "material") setMaterial(value);
    else if (name === "weight") setWeight(value);
    else if (name === "description") setDescription(value);
  };

  const getMasterList = async () => {
    try {
      const res = await axios.get("/masters");
      const { colors, sizes } = res?.data;
      const colorList = colors?.map((e) => {
        return { name: e, id: e };
      });
      const sizeList = sizes?.map((e) => {
        return { name: e, id: e };
      });
      setColorsList(colorList || []);
      setSizesList(sizeList || []);
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const productData = {
      title,
      itemNumber,
      brand,
      price: Number(price),
      currency: "USD",
      material,
      weight,
      description,
      category: category,
      sizes: sizes,
      colors: colors,
      types: types,
    };

    const { valid, errors } = validateNewProductData(productData);
    if (!valid) {
      setFormState((prevState) => ({
        ...prevState,
        errors: errors,
      }));
      return;
    }
    setFormState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    try {
      const authToken = localStorage.getItem("Authorization");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const createProductResponse = await axios.post(
        "/admin/product",
        productData
      );

      setFormState((prevState) => ({
        ...prevState,
        loading: false,
        displayModal: true,
        success: createProductResponse.data.message,
      }));

      setTimeout(() => {
        dispatch(getAllProducts());
        navigate("/admin/products");
      }, 1500);
    } catch (error) {
      setFormState((prevState) => ({
        ...prevState,
        displayModal: true,
        loading: false,
        modalError: error.response?.data?.error || "An error occurred",
      }));
      setTimeout(() => {
        setFormState((prevState) => ({
          ...prevState,
          displayModal: false,
          modalError: "",
        }));
      }, 1500);
    }
  };

  const {
    fetchedCategories,
    loading,
    displayModal,
    modalError,
    success,
    errors,
  } = formState;

  return (
    <Layout>
      <Modal active={displayModal} message={success} error={modalError} />
      <div className="columns is-centered">
        <div className="column is-half">
          <h1 className="subtitle">Create Product</h1>
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">Title</label>
              <input
                className="input"
                placeholder="Title"
                name="title"
                value={title}
                onChange={handleChange}
              />
              {!title && errors.title && <Error error={errors.title} />}
            </div>

            <div className="field">
              <label className="label">Item Number</label>
              <input
                className="input"
                placeholder="Item Number"
                name="itemNumber"
                value={itemNumber}
                onChange={handleChange}
              />
              {!itemNumber && errors.itemNumber && (
                <Error error={errors.itemNumber} />
              )}
            </div>

            <div className="field">
              <label className="label">Brand</label>
              <input
                className="input"
                placeholder="Brand"
                name="brand"
                value={brand}
                onChange={handleChange}
              />
              {!brand && errors.brand && <Error error={errors.brand} />}
            </div>

            <div className="field">
              <label className="label">Price</label>
              <input
                className="input"
                placeholder="Price"
                name="price"
                value={price}
                onChange={handleChange}
              />
              {!price && errors.price && <Error error={errors.price} />}
            </div>

            {/* <div className="field">
              <label className="label">Currency</label>

              <MyDropdown
                listName="Select Currency"
                options={CURRENCY_LIST}
                isMulti={false}
                value={currency}
                setState={setCurrency}
              />
              {!currency && errors.currency && (
                <Error error={errors.currency} />
              )}
            </div> */}

            <div className="field">
              <label className="label">Material</label>
              <input
                className="input"
                placeholder="Material"
                name="material"
                value={material}
                onChange={handleChange}
              />
              {!material && errors.material && (
                <Error error={errors.material} />
              )}
            </div>

            <div className="field">
              <label className="label">Weight</label>
              <input
                className="input"
                placeholder="Weight"
                name="weight"
                value={weight}
                onChange={handleChange}
              />
              {!weight && errors.weight && <Error error={errors.weight} />}
            </div>

            <div className="field">
              <label className="label">Description</label>
              <textarea
                className="textarea"
                placeholder="Description"
                name="description"
                value={description}
                onChange={handleChange}
              />
              {!description && errors.description && (
                <Error error={errors.description} />
              )}
            </div>

            <div className="field">
              <label className="label" style={{ marginRight: "10px" }}>
                Select a category
              </label>
              <MyDropdown
                listName="Select Category"
                options={fetchedCategories}
                isMulti={false}
                value={category}
                setState={setCategory}
              />
              {!category && errors.category && (
                <Error error={errors.category} />
              )}
            </div>

            <div className="Select color">
              <label className="label">Select Color</label>
              <MyDropdown
                options={colorsList}
                isMulti={true}
                value={colors || []}
                setState={setColors}
              />
              {(!colors || colors.length === 0) && errors.colors && (
                <Error error={errors.colors} />
              )}
            </div>
            <br />

            <div className="Select Size">
              <label className="label">Select Sizes</label>
              <MyDropdown
                options={sizesList}
                isMulti={true}
                value={sizes || []}
                setState={setSizes}
              />
              {(!sizes || sizes.length === 0) && errors.sizes && (
                <Error error={errors.sizes} />
              )}
            </div>
            <br />
            <div className="Select Size">
              <label className="label">Select Type</label>
              <MyDropdown
                options={typesList}
                isMulti={true}
                value={types || []}
                setState={setTypes}
              />
              {(!types || types.length === 0) && errors.types && (
                <Error error={errors.types} />
              )}
            </div>
            <br />
            <button className="button is-primary" style={{ width: "100%" }}>
              Create
            </button>
          </form>

          {loading && <Loader />}
        </div>
      </div>
    </Layout>
  );
};

export default NewProduct;
