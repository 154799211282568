import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";

// components
import Layout from "../../components/Layout/Layout";
import Modal from "../../components/Modal/Modal";
import Loader from "../../components/Loader/Loader";

// actions
import { getAllProducts } from "../../store/actions/products";

// helpers
import axios from "axios";
import { getDate } from "../../utils/helpers";

const ViewOrder = ({ getAllProducts }) => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    fetchOrderData();
  }, [orderId]);

  const fetchOrderData = async () => {
    try {
      const authToken = localStorage.getItem("Authorization");
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const response = await axios.get("/admin/order/", {
        params: { orderId },
      });
      setOrder(response.data);
      setLoading(false);
    } catch (error) {
      setErrors(error.message);
      setLoading(false);
    }
  };

  const handleShipped = async (id) => {
    try {
      setLoading(true);
      const orderData = { stage: "shipped" };
      const authToken = localStorage.getItem("Authorization");
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const response = await axios.put(`/admin/order/${id}`, orderData);
      setSuccess(response.data.message);
      setDisplayModal(true);
      setTimeout(() => {
        setDisplayModal(false);
        fetchOrderData();
      }, 1500);
    } catch (error) {
      setModalError(error.response?.data?.error || error.message);
      setDisplayModal(true);
      setLoading(false);
      setTimeout(() => {
        setDisplayModal(false);
        setModalError("");
      }, 1500);
    }
  };
  const handleCompleted = async (id) => {
    try {
      setLoading(true);
      const orderData = { stage: "completed" };
      const authToken = localStorage.getItem("Authorization");
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const response = await axios.put(`/admin/order/${id}`, orderData);
      setSuccess(response.data.message);
      setDisplayModal(true);
      setTimeout(() => {
        setDisplayModal(false);
        fetchOrderData();
      }, 1500);
    } catch (error) {
      setModalError(error.response?.data?.error || error.message);
      setDisplayModal(true);
      setLoading(false);
      setTimeout(() => {
        setDisplayModal(false);
        setModalError("");
      }, 1500);
    }
  };

  return (
    <Layout>
      <Modal error={modalError} message={success} active={displayModal} />
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds">
            <div className="card">
              {loading && <Loader />}
              <header className="card-header">
                <p className="card-header-title is-uppercase is-size-4">
                  PRODUCTS
                </p>
              </header>
              <div className="card-content">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th className="has-text-right">Color</th>
                      <th className="has-text-right">Size</th>
                      <th className="has-text-right">Product Price</th>
                      <th className="has-text-right">Quantity</th>
                      <th className="has-text-right">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order &&
                      order.products.map((product) => {
                        const { title, price, currency } = product.details;
                        const { quantity, productId, color, size } = product;
                        return (
                          <tr key={productId}>
                            <td>
                              <Link to={`/admin/products/view/${productId}`}>
                                {title}
                              </Link>
                            </td>
                            <td className="has-text-right">{color}</td>
                            <td className="has-text-right">{size}</td>
                            <td className="has-text-right">
                              {price + " " + currency}
                            </td>
                            <td className="has-text-right">{quantity}</td>
                            <td className="has-text-right">{`${
                              quantity * price
                            } ${currency}`}</td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td />
                      <td />
                      <td className="has-text-right">Total</td>
                      <td className="has-text-right">
                        {order &&
                          order.products.reduce((acc, item) => {
                            return Math.round(
                              acc + Number(item.details.price * item.quantity)
                            );
                          }, 0)}
                        {" USD"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <footer className="card-footer">
                {order && order?.stage === "pending" && (
                  <button
                    className="button is-primary"
                    onClick={() => handleShipped(orderId)}
                  >
                    Shipped
                  </button>
                )}
                {order && order?.stage !== "completed" && (
                  <button
                    className="button is-danger"
                    onClick={() => handleCompleted(orderId)}
                  >
                    Completed
                  </button>
                )}
              </footer>
            </div>
          </div>
          <div className="column is-one-third">
            <header className="card-header">
              <p className="card-header-title is-uppercase is-size-4">
                ORDER DETAILS
              </p>
            </header>
            <div className="card">
              {console.log("testing order", order)}
              <table>
                <tbody>
                  <tr>
                    <td>User Name</td>
                    <td>{order?.userName}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{order?.email}</td>
                  </tr>
                  <tr>
                    <td>Created At</td>
                    <td>{getDate(order?.createdAt)}</td>
                  </tr>

                  <tr>
                    <td>Delivery Address</td>
                    <td>{order?.deliveryAddress}</td>
                  </tr>
                  <tr>
                    <td>Billing Address</td>
                    <td>{order?.billingAddress}</td>
                  </tr>
                  <tr>
                    <td>Comments</td>
                    <td>{order?.comments}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{order?.stage}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAllProducts: () => dispatch(getAllProducts()),
});

export default connect(null, mapDispatchToProps)(ViewOrder);
