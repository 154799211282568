import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// components
import Layout from "../../components/Layout/Layout";
import Error from "../../components/Error/Error";
import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modal/Modal";

// helpers
import { validateNewCategoryData } from "../../utils/validators";
import axios from "axios";

const NewCategory = ({ userData }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState("");
  const [categoryImage, setCategoryImage] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target.name === "image") {
      setImage(e.target.files[0]);
      setCategoryImage(URL.createObjectURL(e.target.files[0]));
    } else {
      switch (e.target.name) {
        case "name":
          setName(e.target.value);
          break;
        case "description":
          setDescription(e.target.value);
          break;
        default:
          break;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const categoryData = {
      name,
      description,
      image: image || categoryImage,
    };

    const { valid, errors } = validateNewCategoryData(categoryData);

    if (!valid) {
      setErrors(errors);
      return;
    }

    setLoading(true);

    const authToken = localStorage.getItem("Authorization");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    try {
      const response = await axios.post("/admin/category", categoryData);
      const createCategoryResponse = response.data.message;

      const formData = new FormData();
      formData.append("categoryId", response.data.category.id);
      formData.append("image", image);

      await axios.post("/admin/category/image", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setSuccess(createCategoryResponse);
      setDisplayModal(true);

      setTimeout(() => {
        setDisplayModal(false);
        navigate("/admin/categories");
      }, 1500);
    } catch (error) {
      setModalError(error.response?.data?.error || error.message);
      setDisplayModal(true);

      setTimeout(() => {
        setDisplayModal(false);
        setModalError("");
      }, 1500);
    } finally {
      setLoading(false);
    }
  };

  const removeImage = () => {
    setCategoryImage("");
    setImage("");
  };

  return (
    <Layout>
      <Modal active={displayModal} message={success} error={modalError} />

      <div className="columns is-centered">
        <div className="column is-half">
          <h1 className="subtitle">Create Category</h1>
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">Category name</label>
              <input
                className="input"
                placeholder="Category name"
                name="name"
                value={name}
                onChange={handleChange}
              />
              {!name && errors.name && <Error error={errors.name} />}
            </div>
            <div className="field">
              <label className="label">Description</label>
              <textarea
                className="textarea"
                placeholder="Description"
                name="description"
                value={description}
                onChange={handleChange}
              />
              {!description && errors.description && <Error error={errors.description} />}
            </div>

            {/* <div className="field">
              <label className="label">
                Upload an image
                <input
                  required
                  className="button is-link"
                  type="file"
                  name="image"
                  onChange={handleChange}
                />
              </label>
            </div> */}
            <div className="field">
              <div className="d-flex align-items-start">
                <label className="label button is-primary">
                  {"Upload Image"}
                  <input
                    className="button is-link"
                    type="file"
                    name="image"
                    onChange={handleChange}
                    accept=".png, .jpeg, .jpg"
                  />
                </label>
                {categoryImage && (
                  <div className="position-relative upload upload-image-container">
                    <img className="upload-image" src={categoryImage} alt="" />
                    <span
                      onClick={() => removeImage()}
                      className="delete position-absolute top-0 end-0"
                    >
                      {" "}
                    </span>
                  </div>
                )}
              </div>
              {!categoryImage && errors.image && <Error error={errors.image} />}
            </div>
            <br />
            <button className="button is-primary" type="submit">
              Create
            </button>
          </form>
          {loading && <Loader />}
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  userData: state.user.data,
});

export default connect(mapStateToProps)(NewCategory);
