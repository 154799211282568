import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase,
  signOut as signOutFirebase,
} from "firebase/auth";
import firebaseConfig from "../configs/firebaseConfig";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Set up Google Auth Provider
const provider = new GoogleAuthProvider();

export function signInWithGoogle() {
  return signInWithPopup(auth, provider);
}

export function signInWithEmailAndPassword(email, password) {
  return signInWithEmailAndPasswordFirebase(auth, email, password);
}

export function signOut() {
  return signOutFirebase(auth);
}
