import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";

// components
import Layout from "../../components/Layout/Layout";
import Error from "../../components/Error/Error";
import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modal/Modal";

// helpers
import { validateNewCategoryData } from "../../utils/validators";
import axios from "axios";

const EditCategory = ({ userData }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);

  const navigate = useNavigate();
  const { categoryId } = useParams();

  useEffect(() => {
    fetchCategory();
  }, [categoryId]);

  const fetchCategory = async () => {
    const authToken = localStorage.getItem("Authorization");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    try {
      const response = await axios.get("/admin/category/", {
        params: { categoryId },
      });
      setName(response.data.name);
      setCategoryImage(response.data.imageUrl);
      setDescription(response.data.description);
      setLoading(false);
    } catch (error) {
      setModalError(error.response?.data?.error || error.message);
      setDisplayModal(true);
      setTimeout(() => {
        setDisplayModal(false);
        setModalError("");
      }, 1500);
    }
  };
  const removeImage = () => {
    setCategoryImage("");
    setImage("");
  };

  const handleChange = (e) => {
    if (e.target.name === "image") {
      setImage(e.target.files[0]);
      setCategoryImage(URL.createObjectURL(e.target.files[0]));
    } else if (e.target.name === "name") {
      setName(e.target.value);
    } else if (e.target.name === "description") {
      setDescription(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const categoryData = {
      name,
      description,
      image: image || categoryImage,
    };
    const { valid, errors } = validateNewCategoryData(categoryData);

    if (!valid) {
      setErrors(errors);
      return;
    }

    setLoading(true);

    const authToken = localStorage.getItem("Authorization");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    try {
      const response = await axios.put(
        `/admin/category/${categoryId}`,
        categoryData
      );
      const createCategoryResponse = response.data.message;

      if (image) {
        const formData = new FormData();
        formData.append("categoryId", categoryId);
        formData.append("image", image);

        await axios.post("/admin/category/image", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }

      setSuccess(createCategoryResponse);
      setDisplayModal(true);

      setTimeout(() => {
        navigate(-1);
      }, 1500);
    } catch (error) {
      setModalError(error.response?.data?.error || error.message);
      setDisplayModal(true);
      setTimeout(() => {
        setDisplayModal(false);
        setModalError("");
      }, 1500);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Modal active={displayModal} message={success} error={modalError} />

      <div className="columns is-centered">
        <div className="column is-half">
          <h1 className="subtitle">Edit Category</h1>
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">Category name</label>
              <input
                className="input"
                placeholder="name"
                name="name"
                value={name}
                onChange={handleChange}
              />
              {errors.name && <Error error={errors.name} />}
            </div>
            <div className="field">
              <label className="label">Description</label>
              <textarea
                className="textarea"
                placeholder="description"
                name="description"
                value={description}
                onChange={handleChange}
              />
              {errors.description && <Error error={errors.description} />}
            </div>

            <div className="field">
              <div className="d-flex align-items-start">
                <label className="label button is-primary">
                  {"Upload Image"}
                  <input
                    className="button is-link"
                    type="file"
                    name="image"
                    onChange={handleChange}
                    accept=".png, .jpeg, .jpg"
                  />
                </label>
                {categoryImage && (
                  <div className="position-relative upload upload-image-container">
                    <img className="upload-image" src={categoryImage} alt="" />
                    <span
                      onClick={() => removeImage()}
                      className="delete position-absolute top-0 end-0"
                    >
                      {" "}
                    </span>
                  </div>
                )}
              </div>
              {!categoryImage && errors.image && <Error error={errors.image} />}

            </div>
            <br />
            <button className="button is-primary" type="submit">
              Update
            </button>
          </form>
          {loading && <Loader />}
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  userData: state.user.data,
});

export default connect(mapStateToProps)(EditCategory);
