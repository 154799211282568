import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// components
import Layout from "../../components/Layout/Layout";
import Modal from "../../components/Modal/Modal";

// assets
import User from "../../assets/images/User.png";

// helpers
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { getDate } from "../../utils/helpers";

const ViewCategory = () => {
  const [loading, setLoading] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = location.state || {};
console.log('testing user......',user)
  useEffect(() => {
    if (user) {
      setLoading(false); // Set loading to false once user data is available
    } else {
      navigate("/admin/users"); // Redirect if no user data
    }
  }, [user, navigate]);

  const handleDelete = (id) => {
    const authToken = localStorage.getItem("Authorization");

    axios
      .delete(`/admin/user/${user.email}`, {
        headers: { Authorization: `${authToken}` },
      })
      .then((response) => {
        setDisplayModal(true);
        setSuccess(response.data.message);
        setTimeout(() => {
          setDisplayModal(false);
          navigate("/admin/users");
        }, 1500);
      })
      .catch((error) => {
        setDisplayModal(true);
        setModalError(error.response?.data?.error || "Error deleting user.");
        setTimeout(() => {
          setDisplayModal(false);
          setModalError("");
        }, 1500);
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Layout>
      <Modal error={modalError} message={success} active={displayModal} />
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds">
            <div className="card">
              <header className="card-header">
                <p className="card-header-title">
                  {user?.firstName} {user?.lastName}
                </p>
              </header>
              <div className="card-content">
                <p>
                  <span>First Name:</span> {user?.firstName}
                </p>
                <p>
                  <span>Last Name:</span> {user?.lastName}
                </p>
                <p>
                  <span>E-mail:</span> {user?.email}
                </p>
                <p>
                  <span>Phone:</span> {user?.phoneNumber}
                </p>
                <p>
                  <span>Country:</span> {user?.country}
                </p>
                <p>
                  <span>Created at:</span> {getDate(user?.createdAt)}
                </p>
                <p>
                  <span>User ID:</span> {user?.email}
                </p>
              </div>
              <footer className="card-footer">
                <button
                  className="button is-danger"
                  onClick={() => handleDelete(user?.email)}
                >
                  Delete
                </button>
              </footer>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="card">
              <div className="card-image">
                <figure className="image is128by128">
                  <img src={user?.imageUrl || User} alt="user" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ViewCategory;
