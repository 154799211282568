import React from "react";
import "./Switch.css"; // Optional: You can use styled-components or CSS modules if needed

const Switch = ({ id, name, isOn, handleToggle }) => {
  return (
    <div className="switch-container">
      <input
        checked={isOn}
        onChange={handleToggle}
        className="switch-checkbox"
        id={id || "switchInput"}
        name={name || "switchInput"}
        type="checkbox"
      />
      <label className="switch-label" htmlFor={id || "switchInput"}>
        <span className="switch-button" />
      </label>
    </div>
  );
};

export default Switch;
