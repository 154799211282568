import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// components
import Layout from "../../components/Layout/Layout";
import Modal from "../../components/Modal/Modal";

// helpers
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { getDate } from "../../utils/helpers";

const ViewCategory = ({ userData }) => {
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [success, setSuccess] = useState("");
  const { categoryId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategory();
  }, [categoryId]);

  const fetchCategory = async () => {
    try {
      const authToken = localStorage.getItem("Authorization");
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const response = await axios.get("/admin/category/", {
        params: { categoryId },
      });
      setCategory(response.data);
      setLoading(false);
    } catch (error) {
      setModalError(error.response?.data?.error || error.message);
      setDisplayModal(true);
      setLoading(false);
      setTimeout(() => setDisplayModal(false), 1500);
    }
  };

  const handleDelete = async (id) => {
    try {
      const authToken = localStorage.getItem("Authorization");
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const response = await axios.delete(`/admin/category/${id}`);
      setSuccess(response.data.message);
      setDisplayModal(true);
      setTimeout(() => {
        setDisplayModal(false);
        navigate("/admin/categories");
      }, 1500);
    } catch (error) {
      setModalError(error.response?.data?.error || error.message);
      setDisplayModal(true);
      setTimeout(() => setDisplayModal(false), 1500);
    }
  };

 

  return (
    <Layout>
      <Modal error={modalError} message={success} active={displayModal} />
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="columns">
            <div className="column is-two-thirds">
              <div className="card">
                <header className="card-header">
                  <p className="card-header-title">Name: {category.name}</p>
                </header>
                <div className="card-content">
                  <p>
                    <span>Description: </span> {category.description}
                  </p>
                  <p>
                    <span>Created at: </span> {getDate(category.createdAt)}
                  </p>
                </div>
                <footer className="card-footer">
                  <Link to={`/admin/categories/edit/${categoryId}`}>
                    <button className="button is-link">Edit</button>
                  </Link>
                  <button
                    className="button is-danger"
                    onClick={() => handleDelete(categoryId)}
                  >
                    Delete
                  </button>
                </footer>
              </div>
            </div>
            <div className="column is-one-third">
              <div className="card">
                <div className="card-image">
                  <figure className="image is4by3">
                    <img src={category.imageUrl} alt="category" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  userData: state.user.data,
});

export default connect(mapStateToProps)(ViewCategory);
