import React from "react";
import Select from "react-select";

const MyDropDown = ({ options, isMulti, value, setState }) => {
  // Transform the incoming options to match react-select's expected format
  const list = options?.map((option) => ({
    value: option?.id,
    label: option?.name,
  }));

  // Determine the initial value(s) based on whether it's multi-select
  const initialValue = isMulti
    ? value?.map((val) => list?.find((option) => option?.value === val))
    : list?.find((option) => option?.value === value);

  // Handle changes in selection
  const handleChange = (selectedOption) => {
    if (isMulti) {
      // For multi-select, extract an array of selected values
      const selectedValues =
        selectedOption?.map((option) => option?.value) || [];
      setState(selectedValues);
    } else {
      // For single select, extract the single selected value
      const selectedValue = selectedOption?.value || null;
      setState(selectedValue);
    }
  };

  return (
    <Select
      options={list || []}
      isMulti={isMulti}
      value={initialValue}
      onChange={handleChange}
      placeholder="Select..."
    />
  );
};

export default MyDropDown;
