export function chunkArray(myArray, chunk_size) {
  let arrCpy = myArray.slice();
  let results = [];

  while (arrCpy.length) {
    results.push(arrCpy.splice(0, chunk_size));
  }

  return results;
}
export const getDefaultImageUrl = (images) => {
  const defaultImage = images?.filter((image) => image?.default === true);
  return defaultImage?.length > 0 ? defaultImage[0] : null;
};

const moment = require("moment");
export const getDate = (value) => {
  if (!value) return "";
  const date = moment(value);
  const formattedDate = date.format("DD-MM-YYYY hh:mm:ss A");
  return formattedDate;
};
