import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllProducts } from "../../store/actions/products";
import Error from "../../components/Error/Error";
import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modal/Modal";
import MyDropdown from "../../components/Dropdown/multiple-dropdown";
import { validateNewStockData } from "../../utils/validators";
import axios from "axios";
import Switch from "../../components/common/switch";

const ManageStock = ({ getAllProducts }) => {
  const [productData, setProductData] = useState({
    title: "",
    itemNumber: "",
    brand: "",
    price: "",
    currency: "",
    material: "",
    weight: "",
    description: "",
    image: "",
    category: "",
    colors: [],
    sizes: [],
  });
  const [loading, setLoading] = useState(true);
  const [isManageShow, setIsManageShow] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState("");
  const { productId } = useParams();
  const [productImage, setProductImage] = useState(null);
  const [colorsList, setColorList] = useState([]);
  const [sizesList, setSizesList] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [color, setColor] = useState(null);
  const [imagesList, setImagesList] = useState([]);
  const [isAvailable, setIsAvailable] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);

  useEffect(() => {
    if (productId) {
      fetchProductData();
    }
  }, [productId]);

  const updateList = async (newList) => {
    try {
      setLoading(true);
      const sortedList = newList?.sort((a, b) => {
        return (a?.sortOrder || 0) - (b?.sortOrder || 0);
      });
      const response = await axios.put(`/admin/product/${productId}`, {
        images: sortedList,
      });
      setSuccess(response.data.message);
      setLoading(false);
    } catch (error) {
      setModalError(error.response?.data?.error || "Failed to update product.");
      setLoading(false);
    }
  };
  const getMasterList = async (productData) => {
    try {
      setColorList(
        productData?.colors?.map((e) => ({
          name: e,
          id: e,
        }))
      );
      setSizesList(
        productData?.sizes?.map((e) => ({
          name: e,
          id: e,
        }))
      );
    } catch (error) {
      console.error("Error fetching master list:", error);
    }
  };

  const fetchProductData = async () => {
    try {
      const authToken = localStorage.getItem("Authorization");
      axios.defaults.headers.common = { Authorization: `${authToken}` };

      const response = await axios.get(`/admin/product/`, {
        params: { productId },
      });

      getMasterList(response?.data);
      setProductData((prevData) => ({
        ...prevData,
        ...response.data,
      }));
      setImagesList(response?.data?.images || []);
      setIsAvailable(response?.data?.isActive || false);
      setLoading(false);
    } catch (error) {
      setErrors({ fetchError: "Failed to load product data." });
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setProductData((prevState) => ({
      ...prevState,
      [name]: type === "file" ? files[0] : value,
    }));
    if (name === "image") setProductImage(URL.createObjectURL(files[0]));
  };

  const removeImage = () => {
    setProductImage(null);
    setProductData((prevState) => ({
      ...prevState,
      image: null,
    }));
  };

  const handleDelete = async (index) => {
    const newList = imagesList.filter((_, i) => i !== index);
    setImagesList(newList);
    updateList(newList);
    if (newList?.length === 0 && isAvailable) {
      handleStatusToggle();
    }
  };

  const handleEdit = (image, index) => {
    setEditIndex(index);
    setEdit(true);
    setColor(image?.color || null);
    setSizes(image?.sizes || []);
    setProductImage(image?.url || null);
    setSortOrder(image?.sortOrder || null);
    setIsManageShow(true);
  };
  const editItem = async (editIndex) => {
    const oldImage = imagesList[editIndex];
    let newUrl = null;

    if (productData.image) {
      const formData = new FormData();
      formData.append("productId", productId);
      formData.append("image", productData.image);

      const response = await axios.post(
        "/admin/product/imagesArray",
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );

      if (response.data.status) {
        newUrl = response?.data?.imageUrl;
      }
    }
    if (!productData.image && !productImage) {
      setLoading(false);
      alert("Please upload the image.");
      return;
    }
    const newImage = {
      ...oldImage,
      url: newUrl ? newUrl : oldImage?.url,
      sortOrder: sortOrder,
      color,
      sizes,
    };
    const newList = imagesList;
    newList[editIndex] = newImage;
    setIsManageShow(false);
    updateList(newList);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { valid, errors } = validateNewStockData({ color, sizes });

    if (!valid) {
      setErrors(errors);
      return;
    }
    if (edit && editIndex !== null && editIndex !== undefined) {
      setLoading(true);
      editItem(editIndex);
      return null;
    }

    if (!productImage) {
      setLoading(false);
      alert("Please upload the image.");
      return;
    }
    setLoading(true);
    const authToken = localStorage.getItem("Authorization");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    try {
      if (productData.image) {
        const formData = new FormData();
        formData.append("productId", productId);
        formData.append("image", productData.image);

        const response = await axios.post(
          "/admin/product/imagesArray",
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );

        if (response.data.status) {
          const newImage = {
            color,
            url: response?.data?.imageUrl,
            sizes,
            sortOrder: sortOrder,
            default: imagesList?.length === 0 ? true : false,
            isAvailable: true,
          };
          const updatedList = [...imagesList, newImage];
          setImagesList(updatedList);

          await axios.put(`/admin/product/${productId}`, {
            images: updatedList,
          });

          setSuccess(response.data.message);
          setDisplayModal(true);
          setTimeout(() => {
            setColor(null);
            setSizes([]);
            setProductImage(null);
            setErrors({});
            setProductImage(null);
            getAllProducts();
            setLoading(false);
            setDisplayModal(false);
            setIsManageShow(false);
          }, 1500);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setModalError(error.response?.data?.error || "Failed to update product.");
      setLoading(false);
    }
  };
  // Corrected handleToggleDefault function
  const handleToggleDefault = (i) => {
    console.log("testing ", i);
    setImagesList((prevList) => {
      const newList = prevList.map((img, idx) => ({
        ...img,
        default: idx === i, // Set only one default image
      }));
      updateList(newList);
      return newList;
    });
  };

  // Corrected handleImageStatusToggle function
  const handleImageStatusToggle = (i) => {
    setImagesList((prevList) => {
      const newList = prevList.map((img, idx) => {
        if (idx === i) {
          return {
            ...img,
            isAvailable: !img.isAvailable,
          };
        }
        return img;
      });
      updateList(newList);
      return newList;
    });
  };

  const handleStatusToggle = async () => {
    if (productData?.images?.length === 0 && imagesList?.length === 0) {
      alert("Please add Items first.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.put(`/admin/productStatus`, {
        productId: productId,
        isActive: !isAvailable,
      });
      setSuccess(response.data.message);
      setIsAvailable(!isAvailable);
      setLoading(false);
      setDisplayModal(true);
      setTimeout(() => {
        getAllProducts();
        setDisplayModal(false);
      }, 1500);
    } catch (error) {
      setModalError(error.response?.data?.error || "Failed to update product.");
      setLoading(false);
    }
  };
  const handleAddButton = () => {
    if (isManageShow) {
      setColor(null);
      setSizes([]);
      setProductImage(null);
      setErrors({});
    }
    setIsManageShow(!isManageShow);
  };
  return (
    <>
      <Layout>
        <Modal active={displayModal} message={success} error={modalError} />
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <h1 className="subtitle">Manage Stock</h1>
                <p style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Product: {productData?.title}
                </p>
              </div>
              <div className="Select avaible">
                <label className="label">Product Status (Draft/Public): </label>
                <Switch
                  id="productStatus"
                  name="productStatus"
                  isOn={isAvailable}
                  handleToggle={() => handleStatusToggle()}
                />
                {!isAvailable && errors.isAvailable && (
                  <Error error={errors.isAvailable} />
                )}
              </div>
              <br />
              <div>
                <button
                  onClick={handleAddButton}
                  type="submit"
                  className="button  is-primary"
                  style={{ width: "100%" }}
                >
                  {isManageShow ? "Close" : "Add Item"}
                </button>
              </div>
            </div>
            {isManageShow && (
              <div className="columns is-centered">
                <div className="column is-half">
                  {errors.fetchError && <Error error={errors.fetchError} />}
                  <form onSubmit={handleSubmit}>
                    <div className="Select color" style={{ width: "50%" }}>
                      <label className="label">Select Color</label>
                      <MyDropdown
                        value={color}
                        options={colorsList}
                        isMulti={false}
                        setState={setColor}
                      />
                      {errors.color && <Error error={errors.color} />}
                    </div>
                    <div className="Select color" style={{ width: "70%" }}>
                      <label className="label">Select Sizes</label>
                      <MyDropdown
                        value={sizes}
                        options={sizesList}
                        isMulti={true}
                        setState={setSizes}
                      />
                      {errors.sizes && <Error error={errors.sizes} />}
                    </div>
                    <br />
                    <div className="Select color" style={{ width: "70%" }}>
                      <label className="label">Sort Order</label>
                      <input
                        className="input"
                        placeholder="Sort Order"
                        type="number"
                        name="sortOrder"
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value)}
                      />
                      {errors.sortOrder && <Error error={errors.sortOrder} />}
                    </div>
                    <br />
                    <div className="field">
                      <div className="d-flex align-items-start">
                        <label className="label button is-primary">
                          {"Upload Image"}
                          <input
                            className="button is-link"
                            type="file"
                            name="image"
                            onChange={handleChange}
                            accept=".png, .jpeg, .jpg"
                          />
                        </label>
                        {productImage && (
                          <div className="position-relative upload upload-image-container">
                            <img
                              className="upload-image"
                              src={productImage}
                              alt=""
                            />
                            <span
                              onClick={() => removeImage()}
                              className="delete position-absolute top-0 end-0"
                            ></span>
                          </div>
                        )}
                      </div>
                    </div>
                    <br />
                    <button
                      type="submit"
                      className="button is-primary"
                      style={{ width: "100%" }}
                    >
                      Update
                    </button>
                  </form>
                  {loading && <Loader />}
                </div>
              </div>
            )}
            {imagesList && (
              <table
                className="table"
                style={{ width: "100%", marginTop: "30px" }}
              >
                <thead>
                  <tr>
                    <th>Color</th>
                    <th>Sizes</th>
                    <th>Image</th>
                    <th>Sort Order</th>
                    <th>Default Image</th>
                    <th>Availability</th>
                    <th>Delete Stock</th>
                    <th>Edit Stock</th>
                  </tr>
                </thead>
                <tbody>
                  {imagesList.map((image, index) => (
                    <tr key={index}>
                      <td>{image.color}</td>
                      <td>{image?.sizes?.join(", ")}</td>
                      <td>
                        <div className="stock-img-container">
                          <img
                            className="stock-img"
                            src={image.url}
                            alt="Small Img"
                          />
                          <div className="preview">
                            <img src={image.url} alt="" srcset="" />
                          </div>
                        </div>
                      </td>
                      <td>{image?.sortOrder || ""}</td>
                      <td>
                        <Switch
                          id={`isDefault-${index}`}
                          name="isDefault"
                          isOn={image.default}
                          handleToggle={() => handleToggleDefault(index)}
                        />
                      </td>
                      <td>
                        <Switch
                          id={`isAvailable-${index}`}
                          name="isAvailable"
                          isOn={image.isAvailable}
                          handleToggle={() => handleImageStatusToggle(index)}
                        />
                      </td>
                      <td>
                        <button
                          onClick={() => handleEdit(image, index)}
                          className="button is-link"
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => handleDelete(index)}
                          className="button is-danger"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {!imagesList?.length && (
              <div className="no-item">No Data Found</div>
            )}
          </div>
        )}
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getAllProducts: () => dispatch(getAllProducts()),
});

export default connect(null, mapDispatchToProps)(ManageStock);
