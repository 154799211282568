import React, { useEffect } from 'react';
import './App.css';
import 'bulma/css/bulma.css';
import { Routes, Route, Navigate } from 'react-router-dom'; // Updated imports

// pages
import Products from './pages/Products/Products';
import Signin from './pages/auth/Signin';
// import Signup from './pages/auth/Signup';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from './store/actions/user';
import NewProduct from './pages/Products/NewProduct';
import EditProduct from './pages/Products/EditProduct';
import ViewProduct from './pages/Products/ViewProduct';
import NewCategory from './pages/Categories/NewCategory';
import Categories from './pages/Categories/Categories';
import EditCategory from './pages/Categories/EditCategory';
import ViewCategory from './pages/Categories/ViewCategory';
import Users from './pages/Users/Users';
import ViewUser from './pages/Users/ViewUser';
import Home from './pages/Home/Home';
import Page404 from './pages/Page404/Page404';
import Orders from './pages/Orders/Orders';
import ViewOrder from './pages/Orders/ViewOrder';
import axios from 'axios';
import ManageStock from './pages/Products/Stock';

axios.defaults.baseURL = 'https://us-central1-dgamerz.cloudfunctions.net/api';
// axios.defaults.baseURL='http://127.0.0.1:5001/dgamerz/us-central1/api'

const App = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const isLoggedIn = state.user.data;

  useEffect(() => {
    if (localStorage.getItem('Authorization')) {
      dispatch(getUserData());
    }
  }, [dispatch]);

  return (
    <div className='App'>
      <Routes>
       <Route path='/admin/signin' element={<Signin />} />
        
        {/* <Route path='/admin/signup' element={<Signup />} /> */}
        
        <Route
          path='/admin/'
          element={isLoggedIn ? <Home /> : <Navigate to='/admin/signin' />}
        />
     
        <Route
          path='/admin/products/'
          element={isLoggedIn ? <Products /> : <Navigate to='/admin/signin' />}
        />
        <Route
          path='/admin/products/new'
          element={isLoggedIn ? <NewProduct /> : <Navigate to='/admin/signin' />}
        />
          <Route
          path='/admin/products/stock/:productId'
          element={isLoggedIn ? <ManageStock /> : <Navigate to='/admin/signin' />}
        />
        <Route
          path='/admin/products/edit/:productId'
          element={isLoggedIn ? <EditProduct /> : <Navigate to='/admin/signin' />}
        />
        <Route
          path='/admin/products/view/:productId'
          element={isLoggedIn ? <ViewProduct /> : <Navigate to='/admin/signin' />}
        />
        <Route
          path='/admin/categories/new'
          element={isLoggedIn ? <NewCategory /> : <Navigate to='/admin/signin' />}
        />
        <Route
          path='/admin/categories/'
          element={isLoggedIn ? <Categories /> : <Navigate to='/admin/signin' />}
        />
        <Route
          path='/admin/categories/edit/:categoryId'
          element={isLoggedIn ? <EditCategory /> : <Navigate to='/admin/signin' />}
        />
        <Route
          path='/admin/categories/view/:categoryId'
          element={isLoggedIn ? <ViewCategory /> : <Navigate to='/admin/signin' />}
        />
        <Route
          path='/admin/users/'
          element={isLoggedIn ? <Users /> : <Navigate to='/admin/signin' />}
        />
        <Route
          path='/admin/users/view'
          element={isLoggedIn ? <ViewUser /> : <Navigate to='/admin/signin' />}
        />
        <Route
          path='/admin/orders/'
          element={isLoggedIn ? <Orders /> : <Navigate to='/admin/signin' />}
        />
        <Route
          path='/admin/orders/view/:orderId'
          element={isLoggedIn ? <ViewOrder /> : <Navigate to='/admin/signin' />}
        />
        <Route
          path='*'
          element={isLoggedIn ? <Page404 /> : <Navigate to='/admin/signin' />}
        /> 
      </Routes>
    </div>
  );
};

export default App;
