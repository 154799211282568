import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// components
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import Error from "../../components/Error/Error";
import Modal from "../../components/Modal/Modal";

// helpers
import axios from "axios";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [success, setSuccess] = useState("");
  const userLoading = useSelector((state) => state.user.loading);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    const authToken = localStorage.getItem("Authorization");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .get("/admin/users")
      .then((response) => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setDisplayModal(true);
        setModalError(error.response.data.message);
        setTimeout(() => {
          setDisplayModal(false);
          setModalError("");
        }, 1500);
      });
  };

  const handleDelete = (email) => {
    const authToken = localStorage.getItem("Authorization");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .delete(`/admin/user/${email}`)
      .then((response) => {
        setDisplayModal(true);
        setSuccess(response.data.message);
        setTimeout(() => {
          setDisplayModal(false);
          fetchUsers();
        }, 1500);
      })
      .catch((error) => {
        setDisplayModal(true);
        setModalError(error.response.data.error);
        setTimeout(() => {
          setDisplayModal(false);
          setModalError("");
        }, 3000);
      });
  };

  if (userLoading) {
    return <Loader />;
  }

  return (
    <Layout>
      <Modal error={modalError} message={success} active={displayModal} />
      <div className="control">
        <h1 className="subtitle">Users</h1>
        {loading ? <Loader /> : null}
      </div>
      {users.length > 0 ? (
        <table className="table" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>E-mail</th>
              <th>View User</th>
              <th>Delete User</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.uid}>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                <td>
                  <Link to="/admin/users/view" state={{ user }}>
                    <button className="button is-link">View</button>
                  </Link>
                </td>
                <td>
                  <button
                    className="button is-danger"
                    onClick={() => handleDelete(user.email)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Error />
      )}
    </Layout>
  );
};

export default Users;
