import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// components
import Error from "../../components/Error/Error";
import Loader from "../../components/Loader/Loader";

// actions
import { loginUser, updateError } from "../../store/actions/user";

// helpers
import { validateLoginData } from "./../../utils/validators";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const userData = useSelector((state) => state.user.data);
  const userLoading = useSelector((state) => state.user.loading);
  const userError = useSelector((state) => state.user.error);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      navigate("/admin/products");
    }
  }, [userData, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { valid, errors } = validateLoginData({ email, password });

    if (!valid) {
      dispatch(updateError(errors));
    } else {
      dispatch(loginUser(email, password));
    }
  };

  if (userLoading) {
    return <Loader />;
  }

  return (
    <div
      className="columns is-centered is-vcentered"
    style={{width:"100%",height:"100vh"}}
    >
      <div className="column is-one-quarter">
        <form onSubmit={handleSubmit}>
          <h1 className="title">Sign in</h1>
          {userError.error && <Error error={userError.error} />}
          <div className="field">
            <label className="label">Email</label>
            <input
              className="input"
              placeholder="Email"
              name="email"
              value={email}
              onChange={handleChange}
            />
            {userError.email && <Error error={userError.email} />}
          </div>
          <div className="field">
            <label className="label">Password</label>
            <input
              className="input"
              placeholder="Password"
              name="password"
              type="password"
              value={password}
              onChange={handleChange}
            />
            {userError.password && <Error error={userError.password} />}
          </div>
          <button className="button is-primary">Submit</button>
        </form>
        {/* <Link to="/admin/signup">Need an account? Sign Up</Link> */}
      </div>
    </div>
  );
};

export default Signin;
