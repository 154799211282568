import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../../store/actions/user';
import './Header.css';

const Header = () => {
  const navigate = useNavigate();  // Use useNavigate instead of useHistory
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);

  const handleLogOut = () => {
    navigate('/admin/signin');  // Navigate to sign-in page
    dispatch(logoutUser());
  };

  return (
    <header>
      <nav className='navbar navbar-bottom'>
        <div className='container is-fluid navbar-container'>
          <div>
            <Link to='/admin/products'>
              <h3 className='title'>Admin Panel</h3>
            </Link>
          </div>
          <div className='navbar-item'>
            {user && <p>{`Hi, ${user.firstName} ${user.lastName}`}</p>}
          </div>
          <div className='navbar-item'>
            <div className='navbar-buttons'>
              <button className='button is-link' onClick={handleLogOut}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
