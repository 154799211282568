import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// components
import Layout from '../../components/Layout/Layout';
import Loader from '../../components/Loader/Loader';

const Home = () => {
  // Access user data from Redux store
  const user = useSelector((state) => state.user);

  useEffect(() => {
    // Any logic to run on component mount or update
  }, []); // Empty dependency array means this runs once on mount

  if (user.loading) {
    return <Loader />;
  }

  return (
    <Layout>
      {/* <Modal error={modalError} message={success} active={displayModal} /> */}
      <p>In development</p>
    </Layout>
  );
};

export default Home;
