import React from 'react';
import { Link, useLocation } from 'react-router-dom'; // Updated import
import './Aside.css';

const Aside = () => {
  const location = useLocation(); // Replaces useHistory
  const isActive = (currentPath) => location?.pathname?.includes(currentPath);

  return (
    <aside className='menu'>
      <ul className='menu-list'>
        <li />
        <li>
          <Link className={isActive('/admin/users') ? 'is-active' : ''} to={'/admin/users/'}>
            Users
          </Link>
        </li>
        <li>
          <Link className={isActive('/admin/products') ? 'is-active' : ''} to={'/admin/products/'}>
            Products
          </Link>
        </li>
        <li>
          <Link className={isActive('/admin/categories') ? 'is-active' : ''} to={'/admin/categories/'}>
            Categories
          </Link>
        </li>
        <li>
          <Link className={isActive('/admin/orders') ? 'is-active' : ''} to={'/admin/orders'}>
            Orders
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default Aside;
