import { createStore, combineReducers, applyMiddleware } from 'redux';
import { userReducer } from './reducers/user';
import { productsReducer } from './reducers/products';

import logger from 'redux-logger';
import { thunk } from 'redux-thunk'; // Correct import for redux-thunk

// Combine reducers
const rootReducer = combineReducers({
  products: productsReducer,
  user: userReducer,
});

// Set up middleware
const middlewares = [thunk];
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

// Create the store with middleware
const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
