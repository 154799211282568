import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

// components
import Error from "../../components/Error/Error";
import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modal/Modal";
import MyDropdown from "../../components/Dropdown/multiple-dropdown";

// helpers
import { validateNewProductData } from "../../utils/validators";
import { CURRENCY_LIST } from "../../utils/constants";
const typesList = [
  { name: "Male", id: "Male" },
  { name: "Female", id: "Female" },
];

const NewProduct = () => {
  // Separate state for each field
  const [title, setTitle] = useState("");
  const [itemNumber, setItemNumber] = useState("");
  const [brand, setBrand] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [material, setMaterial] = useState("");
  const [weight, setWeight] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [types, setTypes] = useState([]);
  const [fetchedCategories, setFetchedCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState("");
  const { productId } = useParams();
  const navigate = useNavigate();
  const [colorsList, setColorsList] = useState([]);
  const [sizesList, setSizesList] = useState([]);

  useEffect(() => {
    fetchCategories();
    getMasterList();
  }, [productId]);

  const fetchCategories = () => {
    const authToken = localStorage.getItem("Authorization");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios
      .get("/admin/categories")
      .then((response) => {
        setFetchedCategories(response.data);
      })
      .catch((error) => {
        setDisplayModal(true);
        setModalError(error.response.data.error);
        setTimeout(() => {
          setDisplayModal(false);
          setModalError("");
        }, 1500);
      });
  };

  useEffect(() => {
    if (productId && fetchedCategories) {
      fetchProductData();
    }
  }, [fetchedCategories, productId]);

  const getMasterList = async () => {
    try {
      const res = await axios.get("/masters");
      const { colors, sizes } = res?.data;
      const colorList = colors?.map((e) => {
        return { name: e, id: e };
      });
      const sizeList = sizes?.map((e) => {
        return { name: e, id: e };
      });
      setColorsList(colorList || []);
      setSizesList(sizeList || []);
    } catch (error) {}
  };

  const fetchProductData = () => {
    const authToken = localStorage.getItem("Authorization");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios
      .get(`/admin/product/`, {
        params: { productId },
      })
      .then((response) => {
        const data = response.data;
        setTitle(data.title);
        setItemNumber(data.itemNumber);
        setBrand(data.brand);
        setPrice(data.price);
        setTypes(data?.types);
        setCurrency(data.currency);
        setMaterial(data.material);
        setWeight(data.weight);
        setDescription(data.description);
        setCategory(fetchedCategories.find((o) => o.name == data.category)?.id);
        setColors(data.colors);
        setSizes(data.sizes);
        setLoading(false);
        debugger;
      })
      .catch((error) => {
        setErrors(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") setTitle(value);
    else if (name === "itemNumber") setItemNumber(value);
    else if (name === "brand") setBrand(value);
    else if (name === "price") setPrice(value);
    else if (name === "currency") setCurrency(value);
    else if (name === "material") setMaterial(value);
    else if (name === "weight") setWeight(value);
    else if (name === "description") setDescription(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const productData = {
      title,
      itemNumber,
      brand,
      price,
      currency,
      material,
      weight,
      description,
      category,
      colors,
      sizes,
      types,
    };
    const { valid, errors } = validateNewProductData(productData);

    if (!valid) {
      setErrors(errors);
      return;
    }

    setLoading(true);
    const authToken = localStorage.getItem("Authorization");
    axios.defaults.headers.common = { Authorization: `${authToken}` };

    axios
      .put(`/admin/product/${productId}`, productData)
      .then((response) => {
        setSuccess(response.data.message);
        setLoading(false);
        setDisplayModal(true);
        setTimeout(() => {
          navigate(-1); // Go back to previous page
        }, 1500);
      })
      .catch((error) => {
        setDisplayModal(true);
        setLoading(false);
        setModalError(error.response.data.error);
        setTimeout(() => {
          setDisplayModal(false);
          setModalError("");
        }, 1500);
      });
  };

  return (
    <Layout>
      <Modal active={displayModal} message={success} error={modalError} />
      <div className="columns is-centered">
        <div className="column is-half">
          <h1 className="subtitle">Update product</h1>
          {Object.keys(errors).length > 0 && (
            <Error error={"Could not update product!"} />
          )}
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">Title</label>
              <input
                className="input"
                placeholder="Title"
                name="title"
                value={title}
                onChange={handleChange}
              />
              {!title && errors.title && <Error error={errors.title} />}
            </div>

            <div className="field">
              <label className="label">Item Number</label>
              <input
                className="input"
                placeholder="Item Number"
                name="itemNumber"
                value={itemNumber}
                onChange={handleChange}
              />
              {!itemNumber && errors.itemNumber && (
                <Error error={errors.itemNumber} />
              )}
            </div>

            <div className="field">
              <label className="label">Brand</label>
              <input
                className="input"
                placeholder="Brand"
                name="brand"
                value={brand}
                onChange={handleChange}
              />
              {!brand && errors.brand && <Error error={errors.brand} />}
            </div>

            <div className="field">
              <label className="label">Price</label>
              <input
                className="input"
                placeholder="Price"
                name="price"
                value={price}
                onChange={handleChange}
              />
              {!price && errors.price && <Error error={errors.price} />}
            </div>

            <div className="field">
              <label className="label">Currency</label>
              <MyDropdown
                listName="Select Currency"
                options={CURRENCY_LIST}
                isMulti={false}
                value={currency}
                setState={setCurrency}
              />
              {!currency && errors.currency && (
                <Error error={errors.currency} />
              )}
            </div>

            <div className="field">
              <label className="label">Material</label>
              <input
                className="input"
                placeholder="Material"
                name="material"
                value={material}
                onChange={handleChange}
              />
              {!material && errors.material && (
                <Error error={errors.material} />
              )}
            </div>

            <div className="field">
              <label className="label">Weight</label>
              <input
                className="input"
                placeholder="Weight"
                name="weight"
                value={weight}
                onChange={handleChange}
              />
              {!weight && errors.weight && <Error error={errors.weight} />}
            </div>

            <div className="field">
              <label className="label">Description</label>
              <textarea
                className="textarea"
                placeholder="Description"
                name="description"
                value={description}
                onChange={handleChange}
              />
              {!description && errors.description && (
                <Error error={errors.description} />
              )}
            </div>

            <div className="field">
              <label className="label" style={{ marginRight: "10px" }}>
                Select a category
              </label>
              <MyDropdown
                listName="Select Category"
                options={fetchedCategories}
                isMulti={false}
                value={category}
                setState={setCategory}
              />
              {!category && errors.category && (
                <Error error={errors.category} />
              )}
            </div>

            <div className="Select color">
              <label className="label">Select Color</label>
              <MyDropdown
                options={colorsList}
                isMulti={true}
                value={colors || []}
                setState={setColors}
              />
              {(!colors || colors.length === 0) && errors.colors && (
                <Error error={errors.colors} />
              )}
            </div>
            <br />

            <div className="Select Size">
              <label className="label">Select Sizes</label>
              <MyDropdown
                options={sizesList}
                isMulti={true}
                value={sizes || []}
                setState={setSizes}
              />
              {(!sizes || sizes.length === 0) && errors.sizes && (
                <Error error={errors.sizes} />
              )}
            </div>
            <br />
            <div className="Select Size">
              <label className="label">Select Gender</label>
              <MyDropdown
                options={typesList}
                isMulti={true}
                value={types || []}
                setState={setTypes}
              />
              {(!types || types.length === 0) && errors.types && (
                <Error error={errors.types} />
              )}
            </div>
            <br />
            <button className="button is-primary" style={{ width: "100%" }}>
              Update Product
            </button>
          </form>
          {loading && <Loader />}
        </div>
      </div>
    </Layout>
  );
};

export default NewProduct;
