import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";

// components
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modal/Modal";
import Pagination from "../../components/Pagination/Pagination";

// helpers
import axios from "axios";
import { chunkArray } from "../../utils/helpers";
import {
  NUMBER_OF_PRODUCTS_ON_PAGE,
  PAGINATION_STYLE,
  PAGINATION_SIZE,
  NUMBER_OF_PAGINATION_ELEMENTS,
  PAGINATION_ALIGNMENT,
} from "../../constants/pagination";

const Categories = ({ user }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [success, setSuccess] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const location = useLocation();

  useEffect(() => {
    const queryPage = new URLSearchParams(location?.search).get("page") || 1;
    setPageNumber(parseInt(queryPage, 10));
    fetchCategories();
  }, [location?.search]);

  const fetchCategories = async () => {
    try {
      const authToken = localStorage.getItem("Authorization");
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const response = await axios.get("/admin/categories");
      setCategories(response.data);
      setLoading(false);
    } catch (error) {
      setModalError(error.response?.data?.error || error.message);
      setDisplayModal(true);
      setLoading(false);
      setTimeout(() => setDisplayModal(false), 1500);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const authToken = localStorage.getItem("Authorization");
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const response = await axios.delete(`/admin/category/${id}`);
      setSuccess(response.data.message);
      setLoading(false);
      setDisplayModal(true);
      setTimeout(() => {
        setDisplayModal(false);
        fetchCategories();
      }, 1500);
    } catch (error) {
      setLoading(false);
      setModalError(error.response?.data?.error || error.message);
      setDisplayModal(true);
      setTimeout(() => setDisplayModal(false), 1500);
    }
  };

  const pageLink = (no) =>
    no === 1 ? "/admin/categories/" : `/admin/categories/?page=${no}`;

  const displayedCategories = chunkArray(
    categories,
    NUMBER_OF_PRODUCTS_ON_PAGE
  );
  const numberOfPages = Math.ceil(
    categories.length / NUMBER_OF_PRODUCTS_ON_PAGE
  );

  if (user.loading) {
    return <Loader />;
  }

  return (
    <Layout>
      <Modal error={modalError} message={success} active={displayModal} />
      <div className="control">
        <h1 className="subtitle">Categories</h1>
        <Link to="/admin/categories/new" className="button is-primary">
          New Category
        </Link>
        {loading && <Loader />}
      </div>
      {displayedCategories[pageNumber - 1] && (
        <table className="table" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Title</th>
              <th>View</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {displayedCategories[pageNumber - 1].map((category) => (
              <tr key={category.id}>
                <td>{category.name}</td>
                <td>
                  <Link to={`/admin/categories/view/${category.id}`}>
                    <button className="button is-link">View</button>
                  </Link>
                </td>
                <td>
                  <Link to={`/admin/categories/edit/${category.id}`}>
                    <button className="button is-link">Edit</button>
                  </Link>
                </td>
                <td>
                  <button
                    className="button is-danger"
                    onClick={() => handleDelete(category.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Pagination
        total={numberOfPages}
        active={pageNumber}
        size={PAGINATION_SIZE}
        style={PAGINATION_STYLE}
        alignment={PAGINATION_ALIGNMENT}
        show={NUMBER_OF_PAGINATION_ELEMENTS}
        pageLink={pageLink}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.data,
});

export default connect(mapStateToProps)(Categories);
