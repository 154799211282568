import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";

// components
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modal/Modal";
import Pagination from "../../components/Pagination/Pagination";

// helpers
import axios from "axios";
import { chunkArray, getDate } from "../../utils/helpers";
import {
  NUMBER_OF_PRODUCTS_ON_PAGE,
  PAGINATION_STYLE,
  PAGINATION_SIZE,
  NUMBER_OF_PAGINATION_ELEMENTS,
  PAGINATION_ALIGNMENT,
} from "../../constants/pagination";

const Orders = ({ user }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [success, setSuccess] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const location = useLocation();
  useEffect(() => {
    const queryPage = new URLSearchParams(location?.search).get("page") || 1;
    setPageNumber(parseInt(queryPage, 10));
    fetchOrders();
  }, [location?.search]);

  const fetchOrders = async () => {
    try {
      const authToken = localStorage.getItem("Authorization");
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const response = await axios.get("/admin/orders");
      setOrders(response.data);
      setLoading(false);
    } catch (error) {
      setModalError(error.response?.data?.error || error.message);
      setDisplayModal(true);
      setLoading(false);
      setTimeout(() => setDisplayModal(false), 1500);
    }
  };

  const pageLink = (no) =>
    no === 1 ? "/admin/orders/" : `/admin/orders/?page=${no}`;

  const displayedOrders = chunkArray(orders, NUMBER_OF_PRODUCTS_ON_PAGE);
  const numberOfPages = Math.ceil(orders.length / NUMBER_OF_PRODUCTS_ON_PAGE);

  if (user.loading) {
    return <Loader />;
  }

  return (
    <Layout>
      <Modal error={modalError} message={success} active={displayModal} />
      <div className="control">
        <h1 className="subtitle">Orders</h1>
        {loading && <Loader />}
      </div>
      {displayedOrders[pageNumber - 1] && (
        <table className="table" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Order Id</th>
              <th>Transaction Id</th>
              <th>Payment Status</th>
              <th>User Name</th>
              <th>Date</th>
              <th>Order Status</th>
              <th>View Order</th>
            </tr>
          </thead>
          <tbody>
            {displayedOrders[pageNumber - 1].map((order) => (
              <tr key={order.id}>
                {console.log("testing order:", order)}
                <td>{order?.orderId}</td>
                <td>{order?.transactionId}</td>
                <td>{order?.paymentStatus}</td>
                <td>{order?.userName}</td>
                <td>{getDate(order?.createdAt)}</td>
                <td>{order?.stage}</td>
                <td>
                  <Link to={`/admin/orders/view/${order.id}`}>
                    <button className="button is-link">View</button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Pagination
        total={numberOfPages}
        active={pageNumber}
        size={PAGINATION_SIZE}
        style={PAGINATION_STYLE}
        alignment={PAGINATION_ALIGNMENT}
        show={NUMBER_OF_PAGINATION_ELEMENTS}
        pageLink={pageLink}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.data,
});

export default connect(mapStateToProps)(Orders);
